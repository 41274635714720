import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image'
import { AnimationOnScroll } from 'react-animation-on-scroll';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'gatsby';

const Sposor = styled.section`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    h2{
        font-size: 1.6em;
        font-weight: 300;
        text-align: center;
    }

    div{
        display: block;
        padding: 20px;
        margin: auto;
        justify-content: center;
        div{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }
        .img{
            margin: 20px;
            /* padding-bottom: 40px; */
            width: auto;
            max-width: 250px;
            height: 100px;
            padding: auto;
            padding: 20px;
        }

        .marg{
            margin: 20px;
            padding: auto;          
        }
    }

    ${({theme})=> theme.media.tablet}{
        max-width: 100vw;
        margin: auto;     
    }
`



function Sponsor() {
  return (
    <Sposor>
        {/* <div>
            <h2>SPONSOR TYTULARNY</h2>
            <a href='https://www.morecycling.pl/' target={"_blank"} rel="noreferrer" className='icon' >
                <AnimationOnScroll animateIn="animate__zoomIn" duration={1} animateOnce={true}>
                    <StaticImage
                        src="../assets/images/l_more.jpeg"
                        alt="more cycling odzież kolarska"
                        placeholder="blurred"
                        layout='constrained'
                        className='img'
                        loading='eager'
                        
                        ></StaticImage>
                </AnimationOnScroll>
            </a>
        </div> */}
        <div>
            <h2>PARTNERZY</h2>
            <div> 
                <a href='https://ass-magic.com' target={"_blank"} rel="noreferrer" className='icon' >
                    <AnimationOnScroll animateIn="animate__zoomIn" duration={1} animateOnce={true}>
                        <StaticImage
                        src="../assets/images/assmagic.png"
                        alt="Kremy przeciwko otarciom dla kolarzy i triathlonistów Ass Magic."
                        placeholder="blurred"
                        layout='constrained'
                        className='marg'
                        loading='auto'
                        width={80}
                        height={80}
                        ></StaticImage>
                    </AnimationOnScroll>
                </a>
                <a href='https://braciaziolkowscy.pl' target={"_blank"} rel="noreferrer" className='icon' >
                    <AnimationOnScroll animateIn="animate__zoomIn" duration={1} animateOnce={true}>
                        <StaticImage
                        src="../assets/images/bracia_logo.png"
                        alt="Bracia Ziółkowscy Palarnia Kawy"
                        placeholder="blurred"
                        layout='constrained'
                        className='marg'
                        loading='auto'
                        width={80}
                        height={80}
                        ></StaticImage>
                    </AnimationOnScroll>
                </a>
                <a href='https://browarfortuna.pl/' target={"_blank"} rel="noreferrer" className='icon'>
                    <AnimationOnScroll animateIn="animate__zoomIn" duration={1} animateOnce={true}>
                        <StaticImage
                        src="../assets/images/fortuna.png"
                        alt="Browar Fortuna"
                        placeholder="blurred"
                        layout='constrained'
                        className='img'
                        loading='auto'
                        height={80}
                        width={120}
                        
                        ></StaticImage>
                    </AnimationOnScroll>
                </a>
                <a href='https://eurowafel.pl/Home-2.html' target={"_blank"} rel="noreferrer" className='icon'>
                    <AnimationOnScroll animateIn="animate__zoomIn" duration={1} animateOnce={true}>
                        <StaticImage
                        src="../assets/images/eurowafel.png"
                        alt="Euro Wafel"
                        placeholder="blurred"
                        layout='constrained'
                        className='img'
                        loading='auto'
                        height={90}
                        // width={178}
                        
                        ></StaticImage>
                    </AnimationOnScroll>
                </a>
                {/* <a href='https://morehomecycling.pl' target={"_blank"} rel="noreferrer" className='icon' >
                    <AnimationOnScroll animateIn="animate__zoomIn" duration={1} animateOnce={true}>
                        <StaticImage
                        src="../assets/images/logo_home.jpeg"
                        alt="more home dom dla kolarzy"
                        placeholder="blurred"
                        layout='constrained'
                        className='img'
                        loading='auto'
                        ></StaticImage>
                    </AnimationOnScroll>
                </a> */}
                
                <a href='https://moonsy.pl' target={"_blank"} rel="noreferrer" className='icon'>
                    <AnimationOnScroll animateIn="animate__zoomIn" duration={1} animateOnce={true}>
                        <StaticImage
                        src="../assets/images/Moonsy.png"
                        alt="Moonsy Brown Rice Chips"
                        placeholder="blurred"
                        layout='constrained'
                        className='img'
                        loading='auto'
                        height={100}
                        // width={178}
                        
                        ></StaticImage>
                    </AnimationOnScroll>
                </a>
                {/* <a href='https://narol.pl/?fbclid=IwAR3RP7VIzD3-r6SDsdug_XoCtNjHAdZKiDdX9NZaoNpbGWyYSKa_aXCf8cY' target={"_blank"} rel="noreferrer" className='icon'>
                    <AnimationOnScroll animateIn="animate__zoomIn" duration={1} animateOnce={true}>
                        <StaticImage
                        src="../assets/images/Narol.png"
                        alt="narol - naturalnie na roztoczu"
                        placeholder="blurred"
                        layout='constrained'
                        className='img'
                        loading='auto'
                        
                        ></StaticImage>
                    </AnimationOnScroll>
                </a> */}
                 <a href='https://wilkwowczej.pl' target={"_blank"} rel="noreferrer" className='icon' >
                    <AnimationOnScroll animateIn="animate__zoomIn" duration={1} animateOnce={true}>
                        <StaticImage
                        src="../assets/images/wwologo.png"
                        alt="Wilk w owczej"
                        placeholder="blurred"
                        layout='constrained'
                        className='marg'
                        loading='auto'
                        width={95}
                        height={95}
                        ></StaticImage>
                    </AnimationOnScroll>
                </a>
                <a href='https://rodowita.pl' target={"_blank"} rel="noreferrer" className='icon'>
                    <AnimationOnScroll animateIn="animate__zoomIn" duration={1} animateOnce={true}>
                        <StaticImage
                        src="../assets/images/rodowita.png"
                        alt="Rodowita z Roztocza"
                        placeholder="blurred"
                        layout='constrained'
                        className='img'
                        loading='auto'
                        height={100}
                        width={178}
                        
                        ></StaticImage>
                    </AnimationOnScroll>
                </a>
            
                
               
                <a href='https://www.zentis.pl/' target={"_blank"} rel="noreferrer" className='icon'>
                    <AnimationOnScroll animateIn="animate__zoomIn" duration={1} animateOnce={true}>
                        <StaticImage
                        src="../assets/images/zentis.png"
                        alt="Zentis partner dla przemysłu przetwórczego"
                        placeholder="blurred"
                        layout='constrained'
                        className='img'
                        loading='auto'
                        height={77}
                        // width={250}
                        
                        ></StaticImage>
                    </AnimationOnScroll>
                </a>
                
                </div>
            </div>
            <div>
                    <h2>PATRONAT </h2>
                    <div>
                        <a href='https://www.lasy.gov.pl/pl' target={"_blank"} rel="noreferrer" className='icon' >
                            <AnimationOnScroll animateIn="animate__zoomIn" duration={1} animateOnce={true}>
                                <StaticImage
                                src="../assets/images/lp_logo2.png"
                                alt="Lasy Państwowe"
                                placeholder="blurred"
                                layout='constrained'
                                className='img'
                                loading='auto'
                                ></StaticImage>
                            </AnimationOnScroll>
                        </a>
                        {/* <a href='https://mambaonbike.pl' target={"_blank"} rel="noreferrer" className='icon' >
                            <AnimationOnScroll animateIn="animate__zoomIn" duration={1} animateOnce={true}>
                                <StaticImage
                                src="../assets/images/logo-mamba.png"
                                alt="Mamba on bike -blog rowerowy  "
                                placeholder="blurred"
                                layout='constrained'
                                className='marg'
                                loading='auto'
                                width={80}
                                height={80}
                                ></StaticImage>
                            </AnimationOnScroll>
                        </a> */}
                        <a href='https://roztoczanskipn.pl/pl/' target={"_blank"} rel="noreferrer" className='icon' >
                            <AnimationOnScroll animateIn="animate__zoomIn" duration={1} animateOnce={true}>
                                <StaticImage
                                src="../assets/images/roztoczanski.png"
                                alt="Roztoczański Park Narodowy"
                                placeholder="blurred"
                                layout='constrained'
                                className='marg'
                                loading='auto'
                                width={100}
                                height={100}
                                ></StaticImage>
                            </AnimationOnScroll>
                        </a>
                        <a href='https://susiec.pl' target={"_blank"} rel="noreferrer" className='icon' >
                    <AnimationOnScroll animateIn="animate__zoomIn" duration={1} animateOnce={true}>
                        <StaticImage
                        src="../assets/images/susiec.png"
                        alt="gmina susiec"
                        placeholder="blurred"
                        layout='fullWidth'
                        className='img'
                        loading='auto'
                        // width={85}
                        // height={100}
                        ></StaticImage>
                    </AnimationOnScroll>
                </a>
                <a href='https://www.tomaszow-lubelski.pl' target={"_blank"} rel="noreferrer" className='icon' >
                    <AnimationOnScroll animateIn="animate__zoomIn" duration={1} animateOnce={true}>
                        <StaticImage
                        src="../assets/images/tomaszow.png"
                        alt="Tomaszów Lubelski"
                        placeholder="blurred"
                        layout='fullWidth'
                        className='img'
                        loading='auto'
                        width={85}
                        height={100}
                        ></StaticImage>
                    </AnimationOnScroll>
                </a>
                    </div>
                </div>
    </Sposor>
  )
}

export default Sponsor